import { Injectable } from '@angular/core';
import { AppConfig } from './../../../app.config';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';

/**
 * Definição da classe.
 */
@Injectable()
export class MenuService {

    /**
     * URL da api.
     */
    private baseUrl:string = AppConfig.API_URL + '/menu';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Salvae um menu.
     */
    public saveMenu( menu ):any {
        return this.http.post(this.baseUrl, menu);
    }

    /**
     * Atualizar um menu.
     */
    public updateMenu( menu ):any {
        return this.http.put(this.baseUrl, menu);
    }
    
    /**
     * Busca todos os menus.
     */
    public findAll( ):any {
        return this.http.get(this.baseUrl);
    }

    /**
     * Busca todos os menus.
     */
    public searchListByIdSistema( idSistema ):any {
        return this.http.get(this.baseUrl+'/lista/sistema/'+idSistema);
    }
    
    /**
     * Busca todos os menus.
     */
    public searchById( id ):any {
        return this.http.get(this.baseUrl+'/'+id);
    }

    /**
     * Deletar menu.
     */
    public delete( id ):any {
        return this.http.delete(this.baseUrl+'/'+id);
    }

}
