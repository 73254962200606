import { Injectable } from '@angular/core';
import { AppConfig } from './../../../app.config';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';

/**
 * Definição da classe.
 */
@Injectable()
export class AplicacaoService {

    /**
     * URL da api.
     */
    private baseUrl:string = AppConfig.API_URL + '/aplicacao';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Salvae uma Aplicacao.
     */
    public saveAplicacao( menu ):any {
        return this.http.post(this.baseUrl, menu);
    }
    
    /**
     * Atualiza um usuario.
     */
    public updateAplicacao( usuario ):any {
        return this.http.put(this.baseUrl, usuario);
    }

    /**
     * Busca todos as aplicações.
     */
    public findAll( ):any {
        return this.http.get(this.baseUrl);
    }
    
    /**
     * Busca aplicação.
     */
    public searchById( id ):any {
        return this.http.get(this.baseUrl+'/'+id);
    }

    /**
     * Deletar aplicação.
     */
    public delete( id ):any {
        return this.http.delete(this.baseUrl+'/'+id);
    }

}
