import { Injectable } from '@angular/core';
import { AppConfig } from './../../../app.config';
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';

/**
 * Definição da classe.
 */
@Injectable()
export class AtributoService {

    /**
     * URL da api.
     */
    private baseUrl:string = AppConfig.API_URL + '/atributo';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Busca todos os schemas.
     */
    public findAllByEntidade( idEntidade ):any {  
        return this.http.get(this.baseUrl+'/entidade/'+idEntidade);
    }

    /**
     * Atualiza um registro.
     */
    public update(data): Observable<any> {
        return this.http.put(this.baseUrl, data);
    }
    
    /**
     * Busca atributo por id.
     */
    public findById( idAtributo ):any {        
        return this.http.get(this.baseUrl+'/'+idAtributo);
    }

}
