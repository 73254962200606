import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TabGroupObservable {
  private observable: Observable<any>;

  private observer: any;

  constructor() {
    this.observable = new Observable(observer => (this.observer = observer));
  }

  public getObservable() {
    return this.observable;
  }

  public notify(data) {
    this.observer.next(data);
  }
}
