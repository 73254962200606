import { AppConfig } from './../../../app.config';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

/**
 * Serviço para consulta na api.
 */
@Injectable()
export class SchemaService {

  /**
   * Construtor.
   */
  constructor(private http: HttpClient) { }

  /**
   * Verifica se deve salvar ou atualizar o registro.
   */
  public saveOrUpdate(data): Observable<any> {
    if (data.idSchema) {
      return this.update(data);
    } else {
      return this.save(data);
    }
  }

  /**
   * Remove um registro pelo id.
   */
  public remove(id): Observable<any> {
    return this.http.delete(AppConfig.API_URL + '/schema/' + id);
  }

  /**
   * Atualiza um registro.
   */
  public update(data): Observable<any> {
    return this.http.put(AppConfig.API_URL + '/schema', data);
  }

  /**
   * Salva um registro.
   */
  public save(data): Observable<any> {
    return this.http.post(AppConfig.API_URL + '/schema', data);
  }

  /**
   * Busca um registro pelo id.
   */
  public search(id): Observable<any> {
    return this.http.get(AppConfig.API_URL + '/schema/' + id);
  }

  /**
   * Busca todos os schemas.
   */
  public findAll(): any {
    return this.http.get(AppConfig.API_URL + '/schema');
  }

}
