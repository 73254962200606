import { Injectable } from '@angular/core';
import { AppConfig } from './../../../app.config';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { RequestResponse } from '@app/shared/models/request-response';

/**
 * Definição da classe.
 */
@Injectable()
export class UpdateDbService {

    /**
     * URL da api.
     */
    private baseUrl:string = AppConfig.API_URL + '/updatedb';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Salva uma atualização.
     */
    public saveUpdateDb( updateDb ):any {
        return this.http.post(this.baseUrl, updateDb);
    }

    /**
     * Atualiza um registro.
     */
    public updateUpdateDb( updateDb ):any {
        return this.http.put(this.baseUrl, updateDb);
    }

    /**
     * Realiza chamada para atualizar o dicionário
     *
     * @param dsDicionarioUrl
     */
    public updateDicionario(dsDicionarioUrl: string): Observable<RequestResponse<any>> {
        return this.http.put<RequestResponse<any>>(dsDicionarioUrl, null);
    }

    /**
     * Busca todos os updates.
     */
    public findAll( ):any {
        return this.http.get(this.baseUrl);
    }
    
    /**
     * Busca update.
     */
    public searchById( id ):any {
        return this.http.get(this.baseUrl+'/'+id);
    }

    /**
     * Deletar update.
     */
    public delete( id ):any {
        return this.http.delete(this.baseUrl+'/'+id);
    }

}
