import { AppConfig } from './../../../app.config';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

/**
 * Serviço para consulta na api.
 */
@Injectable()
export class EntidadeService {

  private baseUrl:string = AppConfig.API_URL + '/entidade/';
  /**
   * Construtor.
   */
  constructor(private http: HttpClient) { }
  
  /**
   * Verifica se deve salvar ou atualizar o registro.
   */
  public saveOrUpdate(data): Observable<any> {
    if (data.idEntidade) {
      return this.update(data);
    } else {
      return this.save(data);
    }
  }

  /**
   * Remove um registro pelo id.
   */
  public remove(id): Observable<any> {
    return this.http.delete(this.baseUrl + id);
  }

  /**
   * Atualiza um registro.
   */
  public update(data): Observable<any> {
    return this.http.put(this.baseUrl, data);
  }

  /**
   * Salva um registro.
   */
  public save(data): Observable<any> {
    return this.http.post(this.baseUrl, data);
  }

  /**
   * Busca um registro pelo id.
   */
  public search(id): Observable<any> {
    return this.http.get(this.baseUrl + id);
  }

  /**
   * Busca todas as entidades por schema.
   */
  public findAllBySchema(id): Observable<any> {
    return this.http.get(this.baseUrl +  'schema/' + id);
  }

}
