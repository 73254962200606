import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfig } from '../../app.config';
import { SessionStorageService } from '../../services/sessionstorage.service';
import { HttpClient } from '@angular/common/http';

/**
 * Controle para adicionar rotas e navegar.
 */
@Injectable()
export class AppRoutingController {

  /**
   * Construtor.
   */
  constructor(private router: Router, private http: HttpClient, private sessionService: SessionStorageService) { }

  /**
   * Verifica pra onde deve navegar, caso não tenha feito cache anteriormente dos dados da tela, baixa e manda pra tela.
   */
  public navigate(formulario) {
    sessionStorage.setItem( formulario.dsUrl, JSON.stringify(formulario));

    // setTimeout(() => this.router.navigate([ formulario.dsUrl ]), 500);
    this.router.navigate([ formulario.dsUrl ]);
  }

  goTo( url ) {
    this.router.navigate([ url ]);
  }

  goToCadastro( path ) {
    const pageContent = this.sessionService.getItem( path );
    this.router.navigate([ pageContent.formularioCadastro.dsUrl ]);
  }

  /**
   * Realiza o request pra buscar os dados da tela.
   */
  public getFormulario(dsUrl: string, type: string):any {
    const url: string = AppConfig.API_URL + '/formulario/' + type + '/consulta?dsUrl=' + dsUrl;
    return this.http.get(url);
  }

}
