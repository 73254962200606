import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNumber } from 'is-what';

/**
 * @whatItDoes Força um input a receber apenas valores numéricos.
 *
 * @description Esta diretiva faz com que um input receba valores e então
 * os formata para o padrão numérico brasileiro. Os valores recebidos são
 * tratados para que não possuam letras ou caracteres especiais, a separação
 * milhares e de decimais são adicionadas automaticamente conforme
 * a digitação vai ocorrendo.
 *
 * @class NumericoDirective
 */
@Directive({
  selector: '[numerico]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumericoDirective,
      multi: true,
    },
  ],
})
export class NumericoDirective implements ControlValueAccessor, OnInit {
  @Input()
  numerico;

  /**
   * Valor real do campo sem a formatação visual.
   *
   * @type {number}
   */
  private _value: number;

  @Input()
  get value(): any {
    return this._value;
  }

  /**
   * Ao receber o valor converte para o tipo float e o armazena na variável _value.
   * Após isso, formata o valor para ser apresentado na view.
   *
   * @param value
   */
  set value(value: any) {
    // Converte o valor recebido.
    this._value = parseFloat(value);
    // Formata o valor recebido para ser apresentado na view.
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.formatViewModel(value));
  }

  private onTouched = () => {};

  private onChange: (value: any) => void = () => {};

  constructor(
    private elementRef: ElementRef,
    private _decimal: DecimalPipe,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    if (!this.numerico) {
      this.numerico = 2;
    }
  }

  // Implemented as part of ControlValueAccessor
  writeValue(value: any): void {
    this.value = this.formatValueNumeric(value ? value : 0);
  }

  // Implemented as part of ControlValueAccessor
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Implemented as part of ControlValueAccessor
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    const clearedValue = this.clearValueNumeric($event.target.value);
    this.value = this.formatValueNumeric(clearedValue);
    this.onChange(this.value);
  }

  @HostListener('blur', ['$event'])
  onBlur() {
    this.onTouched();
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    this.setFocusOnEnd();
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.setFocusOnEnd();
  }

  /**
   * Seta o foco no campo para o final da palavra.
   */
  private setFocusOnEnd() {
    const length = this.elementRef.nativeElement.value.length;
    this.elementRef.nativeElement.setSelectionRange(length, length);
  }

  /**
   * Transforma o valor informado para ser apresentado na view.
   *
   * @example
   *
   * // valor retonado 123.456,00
   * const val = formatViewModel('123456.00');
   *
   * @param {string} value
   * @returns {string}
   */
  private formatViewModel(value: any): string {
    if (typeof value !== 'string') {
      return '';
    }

    const pattern = '1.' + this.numerico + '-' + this.numerico;

    return this._decimal.transform(value, pattern, 'pt-br');
  }

  /**
   * Limpa um valor recebido, deixando apenas caracteres numéricos
   * bloqueando assim a digitação dos demais.
   *
   * @param {string} value Valor recebido.
   * @returns {string} Valor processado.
   */
  private clearValueNumeric(value: string): string {
    let clearedValue = value.match(/[0-9]/g) ?? [];

    return clearedValue.join('');
  }

  /**
   * Transforma o valor informado para o formato number.
   *
   * @example
   *
   * // valor retonado 123456.00
   * const val = formatValueNumeric('123456');
   *
   * @param {string} val
   * @returns {string}
   */
  private formatValueNumeric(val: string = ''): string {
    if (isNumber(val)) {
      val = parseFloat(val).toFixed(this.numerico);
    }

    val = val.replace('.', '');

    let len = val.length;

    while (len < this.numerico + 1) {
      val = '0' + val;
      len = val.length;
    }

    val = val.substring(0, len - this.numerico) + '.' + val.substring(len - this.numerico, len);

    while (val.length > 4 && (val[0] === '0' || isNaN(Number(val[0])))) {
      val = val.substring(1);
    }

    if (val[0] === '.') {
      val = '0' + val;
    }

    return val;
  }
}
