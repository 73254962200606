import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../app.config';
import { DownloadService } from './download.service'

/**
 * Serviço para impressão e download de arquivo
 */
@Injectable()
export class DownloadFilesService {
  constructor(private downloadService: DownloadService, private http: HttpClient) {}

  getFile(url, body = null) {
    if (body == null) {
      return this.http.get(url, {
        responseType: 'blob',
        headers: {
          Authorization: this.getAuthorization(),
          'Content-Type': 'application/octet-stream',
        },
      });
    } else {
      return this.http.post(url, body, {
        responseType: 'blob',
        headers: {
          Authorization: this.getAuthorization(),
          'Content-Type': 'application/json',
        },
      });
    }
  }

  downloadFile(url, extensao = null, name = null) {
    return new Observable((observer) => {
      this.getFile(url).subscribe((content) => {
        name = name ? name : url;
        if (extensao) {
          this.downloadService.download(name + extensao, content);
        } else {
          this.downloadService.download(name, content);
        }
        observer.next();
      });
    });
  }

  async downloadFileSync(url, extensao = null, body = null) {
    const content = await this.getFile(url, body).toPromise();
    if (extensao) {
      await this.downloadService.download(url + extensao, content);
    } else {
      await this.downloadService.download(url, content);
    }
  }

  /**
   * Imprime arquivo PDF
   * @param url
   */
  printFile(url) {
    return new Observable((observer) => {
      this.getFile(url).subscribe(async (content) => {
        const objFra: any = document.createElement('iframe');
        const pdfFile = new Blob([content], {
          type: 'application/pdf',
        });
        const pdfUrl = URL.createObjectURL(pdfFile);
        objFra.id = 'iframe';
        objFra.name = 'iframe';
        objFra.style.visibility = 'hidden';

        if (navigator.userAgent.toLowerCase().includes('firefox')) {
          this.printFirefox(pdfUrl, objFra, observer);
        } else {
          this.printChrome(objFra, pdfUrl);
        }
      });
    });
  }

  /**
   * Impressão especializada para Chrome e outros navegadores, exceto Firefox.
   * @param objFra
   * @param pdfUrl
   */
  private printChrome(objFra: any, pdfUrl: string) {
    objFra.src = pdfUrl;
    document.body.appendChild(objFra);
    objFra.contentWindow.focus();
    this.removeIframe();
    objFra.contentWindow.print();
  }

  /**
   * Impressão otimizada para navegadores Firefox
   *
   * @param pdfUrl
   * @param objFra
   * @param observer
   */
  private printFirefox(pdfUrl: string, objFra: any, observer) {
    const viewerUrl = 'assets/pdf.js/web/viewer.html?file=' + pdfUrl;
    objFra.src = viewerUrl;
    document.body.appendChild(objFra);
    objFra.onload = () => {
      objFra.contentWindow.document.addEventListener(
        'pagesloaded',
        () => {
          objFra.contentWindow.print();
          observer.next();
        },
        false
      );
    };
  }

  /**
   * Remove o componente existente antes de gerar nova visualização
   */
  private removeIframe() {
    const focoTela = () => {
      const frame = document.getElementById('iframe');
      document.body.removeChild(frame);
      window.removeEventListener('focus', focoTela);
    };
    window.addEventListener('focus', focoTela);
  }

  getAuthorization() {
    return 'Bearer ' + AppConfig.OAUTH_DATA.access_token;
  }
}
