import { Injectable } from '@angular/core';
import { AppConfig } from './../../../app.config';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';

/**
 * Definição da classe.
 */
@Injectable()
export class ClienteService {
  /**
   * URL da api.
   */
  private baseUrl: string = AppConfig.API_URL + '/cliente';

  /**
   * Construtor.
   */
  constructor(private http: HttpClient) {}

  /**
   * Salvae um cliente.
   */
  public saveCliente(menu): any {
    return this.http.post(this.baseUrl, menu);
  }

  /**
   * Atualiza um cliente.
   */
  public updateCliente(usuario): any {
    return this.http.put(this.baseUrl, usuario);
  }

  /**
   * Busca todos os clientes.
   */
  public findAll(): any {
    return this.http.get(this.baseUrl);
  }

  /**
   * Busca cliente.
   */
  public searchById(id): any {
    return this.http.get(this.baseUrl + '/' + id);
  }

  /**
   * Deletar cliente.
   */
  public delete(id): any {
    return this.http.delete(this.baseUrl + '/' + id);
  }
}
