import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConfig } from '../../app.config';

/**
 * Definição da classe.
 */
@Injectable()
export class OauthService {

  /**
   * Construtor.
   */
  constructor(private http: HttpClient, private router: Router) {}

  doLogout() {
    AppConfig.OAUTH_DATA = undefined;
    
    localStorage.clear();
    sessionStorage.clear();

    this.goToLogin();
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  goToHome() {
    this.router.navigate(['/app']);
  }

  /**
   * Realiza a autenticação.
   */
  public doLogin(user: string, pwd: string) {
    const headers: HttpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded');

    const body: string = this.buildFormUrlencodedParams({
      client_id: AppConfig.CLIENT_ID,
      client_secret: AppConfig.CLIENT_SECRET,
      grant_type: 'password',
      username: user,
      password: pwd
    });

    return this.http.post(AppConfig.OAUTH_URL + '/login', body, {headers: headers});
  }

  /**
   * Atualiza o token.
   */
  public refreshToken(refreshToken) {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const body: string = this.buildFormUrlencodedParams({
      client_id: AppConfig.CLIENT_ID,
      client_secret: AppConfig.CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    });

    return this.http.post(AppConfig.OAUTH_URL + '/login', body, {headers: headers});
  }

  /**
   * Converte um objeto para uma string de parametros.
   */
  public buildFormUrlencodedParams(data: any): string {
    let params = '';

    for (const key in data) {
      params += key + '=' + data[key] + '&';
    }

    return params.substring(0, params.length - 1);
  }

}
