import { HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';

/**
 * Definição da classe.
 */
export class AppConfig {
  /**
   * Id de acesso.
   */
  static CLIENT_ID = 'frontend-astutus';

  /**
   * Chave de acesso.
   */
  static CLIENT_SECRET = 'WPqpK0vtzY44';

  /**
   * Url de autenticação.
   */
  static OAUTH_URL = environment.OAUTH_URL;

  /**
   * Url da api.
   */
  static API_URL = environment.API_URL;

  /**
   * Url da api.
   */
  static ASTUTUS_API_URL = AppConfig.API_URL;

  /**
   * URL do serviço de DNE
   */
  static DNE_URL = environment.DNE_URL;

  /**
   * Url da api.
   */
  static API_URL_CADASTRO = environment.API_URL_CADASTRO;

  /**
   * Url da api de compras.
   */
  static API_URL_COMPRAS = environment.API_URL_COMPRAS;

  /**
   * Url da api de estoque.
   */
  static API_URL_ESTOQUE = environment.API_URL_ESTOQUE;

  /**
   * Url da api de financeiro.
   */
  static API_URL_FINANCEIRO = environment.API_URL_FINANCEIRO;

  /**
   * Url da api de vendas.
   */
  static API_URL_VENDAS = environment.API_URL_VENDAS;

  /**
   * Url da api preços
   */
  static API_URL_PRECOS = environment.API_URL_PRECOS;

  /**
   * Url da api preços
   */
  static API_URL_FISCAL = environment.API_URL_FISCAL;

  /**
   * Url da api sync
   */
  static API_URL_SYNC = environment.API_URL_SYNC;

  /**
   * Url da api report
   */
  static API_URL_REPORT = environment.API_URL_REPORT;

  /**
   * Url da api de notificação
   */
  static API_URL_NOTIFICATION = environment.API_URL_NOTIFICATION;

  /**
   * Sigla do astutus.
   */
  static SIGLA_ASTUTUS = 'ASTUTUS';

  /**
   * Url da api de s3.
   */
  static S3_API_URL = environment.S3_API_URL;

  /**
   * Menu dev.
   */
  static TP_MENU_DEV = 0;

  /**
   * Dados de oauth.
   */
  static OAUTH_DATA: any;

  /**
   * Url da API do Dominio
   */
  static API_URL_DOMINIO = environment.API_URL_DOMINIO;

  /**
   * Url da API de Backups
   */
  static API_URL_BACKUP = environment.API_URL_BACKUP;

  /**
   * Dados do usuário.
   */
  static USER: any;

  static getAuthorizationHeader() {
    return new HttpHeaders().set('Authorization', 'Bearer ' + AppConfig.OAUTH_DATA.access_token);
  }

  static getAuthorizationHeaderWithSchema(schema) {
    return new HttpHeaders()
      .set('Authorization', 'Bearer ' + AppConfig.OAUTH_DATA.access_token)
      .set('schema', schema);
  }
}
