import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import locale from '@angular/common/locales/pt';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppRoutingController } from './pages/app/app.routing.controller';
import { ComboboxService } from './services/combobox/combobox.service';
import { AplicacaoService } from './services/dicionario/aplicacao/aplicacao.service';
import { AtributoService } from './services/dicionario/atributo/atributo.service';
import { ClienteService } from './services/dicionario/cliente/cliente.service';
import { ComboPesquisaService } from './services/dicionario/combopesquisa/combopesquisa.service';
import { EntidadeService } from './services/dicionario/entidade/entidade.service';
import { FormularioCadastroService } from './services/dicionario/formulariocadastro/formulariocadastro.service';
import { FormularioDocumentoService } from './services/dicionario/formulariodocumento/formulariodocumento.service';
import { FormularioPesquisaService } from './services/dicionario/formulariopesquisa/formulariopesquisa.service';
import { FormularioRelatorioService } from './services/dicionario/formulariorelatorio/formulariorelatorio';
import { MenuService } from './services/dicionario/menu/menu.service';
import { RelatorioService } from './services/dicionario/relatorio/relatorio.service';
import { SchemaService } from './services/dicionario/schema/schema.service';
import { SistemaService } from './services/dicionario/sistema/sistema';
import { UpdateDbService } from './services/dicionario/updatedb/updatedb.service';
import { UsuarioService } from './services/dicionario/usuario/usuario.service';
import { ModuloService } from './services/faturamento/modulo.service';
import { PlanoService } from './services/faturamento/plano.service';
import { AuthGuard } from './services/guard/guard.service';
import { OauthService } from './services/oauth/oauth.service';
import { PerfilamentoService } from './services/perfilamento/perfilamento.service';
import { SessionStorageService } from './services/sessionstorage.service';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { CustomReuseStrategy } from './shared/router/custom-reuse-strategy';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    OauthService,
    PerfilamentoService,
    SchemaService,
    EntidadeService,
    AtributoService,
    FormularioPesquisaService,
    FormularioCadastroService,
    MenuService,
    AplicacaoService,
    RelatorioService,
    UsuarioService,
    FormularioRelatorioService,
    FormularioDocumentoService,
    SistemaService,
    ClienteService,
    UpdateDbService,
    AppRoutingController,
    SessionStorageService,
    ComboPesquisaService,
    ModuloService,
    PlanoService,
    ComboboxService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;

    registerLocaleData(locale);
  }
}
