import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import 'rxjs/Rx';

/**
 * Definição da classe.
 */
@Injectable()
export class FormularioDocumentoService {
    

    /**
     * URL da api.
     */
    private baseUrl:string = AppConfig.API_URL + '/documento';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Salvar um documento.
     */
    public saveDocumento( doc ):any {
        return this.http.post(this.baseUrl, doc);
    }

    /**
     * Atualizar um relatorio.
     */
    public updateDocumento( doc ):any {
        return this.http.put(this.baseUrl, doc);
    }

    /**
     * Busca por id do sistema.
     */
    public findByIdSistema( id ):any {
        return this.http.get(this.baseUrl+'/sistema/'+id);
    }
    
    /**
     * Busca todos os schemas.
     */
    public findAll( ):any { 
        return this.http.get(this.baseUrl);
    }
    
    /**
     * Deletar por id.
     */
    public delete( id ):any {     
        return this.http.delete(this.baseUrl+'/'+id);
    }

    /**
     * Busca por id.
     */
    public findById( id ):any {     
        return this.http.get(this.baseUrl+'/'+id);
    }
    
}
