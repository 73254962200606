import { Injectable } from '@angular/core';
import { AppConfig } from './../../../app.config';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';

/**
 * Definição da classe.
 */
@Injectable()
export class ComboPesquisaService {

    /**
     * URL da api.
     */
    private baseUrl:string = AppConfig.API_URL + '/combo/pesquisa';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Busca todos os combos.
     */
    public findAll( ):any {
        return this.http.get(this.baseUrl);
    }

    /**
     * Busca por id.
     */
    public findById( id ):any {
        return this.http.get(this.baseUrl+'/'+id);
    }

    /**
     * Busca por id da entidade.
     */
    public findByIdEntidade( id ):any {
        return this.http.get(this.baseUrl+'/entidade/'+id);
    }

    /**
     * Salvar um combo.
     */
    public saveCombo( combo ):any {
        return this.http.post(this.baseUrl, combo);
    }

    /**
     * Atualizar um combo.
     */
    public updateCombo( combo ):any {
        return this.http.put(this.baseUrl, combo);
    }

    /**
     * Remove um combo.
     */
    public delete( id ):any {
        return this.http.delete(this.baseUrl + '/' + id);
    }

}
