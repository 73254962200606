import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';

/**
 * Definição da classe.
 */
@Injectable()
export class ComboboxService {

    /**
     * URL da api.
     */
    private baseUrl: string = AppConfig.ASTUTUS_API_URL + '/combo/pesquisa';

    /**
     * Recupera combos do localStorage
     */
    private combobox = JSON.parse(localStorage.getItem('combobox'));

    /**
     * Armazena histórico de combos
     */
    private historicoCombo: any = {};

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Busca todos os combos.
     */
    public findAll(): any {
        return this.http.get(this.baseUrl);
    }

    /**
     * Busca entidades dentro combo no storage.
     */
    search(idComboPesquisa, nmEntidade, nmComboPesquisa = null) {
        const historico = this.historicoCombo[idComboPesquisa || nmComboPesquisa || nmEntidade];
        if (historico) {
            return historico;
        }
        const combo =
            this.combobox.find(item => item.idComboPesquisa == idComboPesquisa) ||
            this.combobox.find(item => item.nmComboPesquisa === nmComboPesquisa) ||
            this.combobox.find(item => item.entidade.nmEntidade === nmEntidade);
        if (combo) {
            this.historicoCombo[idComboPesquisa || nmComboPesquisa || nmEntidade] = combo;
            return combo;
        } else {
            return null;
        }
    }
}
