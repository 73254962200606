import { Injectable } from '@angular/core';
import { AppConfig } from './../../../app.config';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';

/**
 * Definição da classe.
 */
@Injectable()
export class SistemaService {

    /**
     * URL da api.
     */
    private baseUrl:string = AppConfig.API_URL + '/sistema';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Salvar um relatorio.
     */
    public saveSistema( sistema ):any {
        return this.http.post(this.baseUrl, sistema);
    }

    /**
     * Atualizar um relatorio.
     */
    public updateSistema( sistema ):any {
        return this.http.put(this.baseUrl, sistema);
    }

    /**
     * Busca todos os schemas.
     */
    public findAll( ):any { 
        return this.http.get(this.baseUrl);
    }

    /**
     * Busca por id.
     */
    public findById( id ):any {     
        return this.http.get(this.baseUrl+'/'+id);
    }

    /**
     * Busca por sigla.
     */
    public findBySigla( sigla ):any {     
        return this.http.get(this.baseUrl+'/sigla/'+sigla);
    }

    /**
     * Deletar por id.
     */
    public delete( id ):any {     
        return this.http.delete(this.baseUrl+'/'+id);
    }

}
