import { Injectable } from '@angular/core';

@Injectable()
export class DownloadService {
  public download(fileName, content) {
    this.saveByteArray(fileName, content);
  }

  /**
   * Realiza o download do array de bytes passado.
   */
  private saveByteArray(fileName, blob) {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}
