import { Injectable } from '@angular/core';
import { AppConfig } from './../../../app.config';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';

/**
 * Definição da classe.
 */
@Injectable()
export class FormularioPesquisaService {

    /**
     * URL da api.
     */
    private baseUrl:string = AppConfig.API_URL + '/formulario/pesquisa';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Busca todos os formulários.
     */
    public findAll( ):any {
        return this.http.get(this.baseUrl);
    }

    /**
     * Busca por id.
     */
    public findById( id ):any {
        return this.http.get(this.baseUrl+'/'+id);
    }

    /**
     * Busca por id do sistema.
     */
    public findByIdSistema( id ):any {
        return this.http.get(this.baseUrl+'/sistema/'+id);
    }

    /**
     * Salvar um formulário.
     */
    public saveFormulario( formulario ):any {
        return this.http.post(this.baseUrl, formulario);
    }

    /**
     * Atualizar um formulário.
     */
    public updateFormulario( formulario ):any {
        return this.http.put(this.baseUrl, formulario);
    }

    /**
     * Remove um formulário.
     */
    public delete( id ):any {
        return this.http.delete(this.baseUrl + '/' + id);
    }

}
