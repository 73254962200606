import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { Observable } from 'rxjs';

/**
 * Definição da classe.
 */
@Injectable()
export class ModuloService {

    /**
     * URL da api.
     */
    private baseUrl: string = AppConfig.API_URL + '/modulo';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Salvar um módulo.
     */
    public saveModulo(modulo): any {
        return this.http.post(this.baseUrl, modulo);
    }

    /**
     * Atualizar um módulo.
     */
    public updateModulo(modulo): any {
        return this.http.put(this.baseUrl, modulo);
    }

    /**
     * Deletar por id.
     */
    public delete(id): any {
        return this.http.delete(this.baseUrl + '/' + id);
    }

    /**
     * Busca por id.
     */
    public findById(id): any {
        return this.http.get(this.baseUrl + '/' + id);
    }

    /**
     * buscar todos os formulários.
     */
    public findAll(): any {
        return this.http.get(this.baseUrl);
    }

    menu(): Observable<any> {
        return this.http.get(AppConfig.API_URL + '/menu/sistema/sigla/NEXUS/0');
    }

}
