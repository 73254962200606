import { Injectable } from '@angular/core';
import { AppConfig } from './../../../app.config';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';

/**
 * Definição da classe.
 */
@Injectable()
export class UsuarioService {

    /**
     * URL da api.
     */
    private baseUrl:string = AppConfig.API_URL + '/usuario';

    /**
     * Construtor.
     */
    constructor(private http: HttpClient) { }

    /**
     * Salva um usuario.
     */
    public saveUsuario( usuario ):any {
        return this.http.post(this.baseUrl, usuario);
    }

    /**
     * Atualiza um usuario.
     */
    public updateUsuario( usuario ):any {
        return this.http.put(this.baseUrl, usuario);
    }
    
    /**
     * Busca todos os usuarios.
     */
    public findAll( ):any {
        return this.http.get(this.baseUrl);
    }
    
    /**
     * Busca usuario.
     */
    public searchById( id ):any {
        return this.http.get(this.baseUrl+'/'+id);
    }

    /**
     * Deletar usuario.
     */
    public delete( id ):any {
        return this.http.delete(this.baseUrl+'/'+id);
    }

}
