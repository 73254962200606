
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import {mergeMap, take, filter, catchError} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';


import 'rxjs/Observable';


import { OauthService } from '../../services/oauth/oauth.service';
import { AppConfig } from '../../app.config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private _oauthService: OauthService;

  private refreshTokenInProgress = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>( null );

  get oauthService() {
    if ( !this._oauthService ) {
      this._oauthService = this.injector.get(OauthService);
    }

    return this._oauthService;
  }

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('Content-Type') !== 'application/x-www-form-urlencoded' &&
        request.headers.get('Content-Type') !== 'application/octet-stream' &&
        request.headers.get('Content-Type') !== 'multipart/form-data' ) {
      request = this.addAuthenticationToken(request);
    }

    return next.handle(request).pipe(catchError(error => {
      if (request.headers.get('Content-Type') === 'application/x-www-form-urlencoded' ||
          request.headers.get('Content-Type') === 'application/octet-stream') {

        return observableThrowError(error);
      }

      if (error.status !== 401) {
        return observableThrowError(error);
      }

      if ( this.refreshTokenInProgress ) {
        return this.refreshTokenSubject.pipe(
          filter(result => result !== null),
          take(1),
          mergeMap(() => next.handle(this.addAuthenticationToken(request))),);
      }

      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.refreshToken().pipe(
        mergeMap(token => {
          AppConfig.OAUTH_DATA = token;

          localStorage.setItem('oauth', JSON.stringify(token));

          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(token);

          return next.handle(this.addAuthenticationToken(request));
        }),
        catchError(err => {
          this.refreshTokenInProgress = false;
          this.oauthService.doLogout();

          return observableThrowError(error);
        }),);
    }));
  }

  private refreshToken() {
    const refreshToken = AppConfig.OAUTH_DATA ? AppConfig.OAUTH_DATA.refresh_token : null;
    return this.oauthService.refreshToken(refreshToken);
  }

  private addAuthenticationToken(request) {
    if ( !AppConfig.OAUTH_DATA ) {
      return request;
    }

    let authHeader = AppConfig.getAuthorizationHeader();
    if(request.headers.get('schema')){
      authHeader = AppConfig.getAuthorizationHeaderWithSchema(request.headers.get('schema'));
    }
    return request.clone({headers: authHeader});
  }

}
